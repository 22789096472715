@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype"), url("../fonts/Roboto-Medium.svg#Roboto") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"), url("../fonts/Roboto-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.eot");
  src: url("../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype"), url("../fonts/Roboto-Black.svg#Roboto") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg#Poppins") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg#Poppins") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg#Poppins") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg#Poppins") format("svg");
  font-weight: 600;
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black:#1C1C1C;
  --color-back-black: #000000;
  --color-subcolor:#B6332B;
  --color-banner: rgba(48, 50, 51, 0.7);
  --color-search: #353535;
  --color-mark: #A9A9A9;
  --color-main-bg: linear-gradient(180.22deg, #333333 -90.46%, #444444 266.25%);
  --color-bc:rgba(48, 50, 51, 0.8);
  --color-banner-career: #B6B6B6;
  --color-brands: radial-gradient(217.95% 217.95% at 48.2% -40.51%, #141414 0%, #2A2A2A 100%);
  --color-career: #292929;
  --Poppins: 'Poppins', sans-serif;
  --Roboto: 'Roboto', sans-serif;
  --back-grey:#212121;
  --back-black:#1D1D1D;
  --back-greyBack: #292929;
  --back-index:#1D1D1D;
  --color-title: #E5E5E5;
  --color-back: #212121;
  --color-back-main: #1D2831;
  --color-footer: #364149;
  --color-footer-border: #D1D1D2;
  --color-reading: #262938;
  --color-resources: #7A7A7A;
  --color-shadow: rgba(79, 178, 246, 0.5);
  --color-blog-social: rgba(29, 40, 49, 0.4);
  --gradient-circle: radial-gradient(36.25% 36.25% at 50% 50%, rgba(79, 178, 246, 0.3) 0%, rgba(24, 26, 29, 0.3) 100%);
}

/* base styles*/
*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

h1 {
  margin: 0;
}

html {
  font-size: 10px;
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 9.49px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 8.88px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 7.11px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 5.33px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
